















import Vue from "vue";
import { JobPostingOption } from "@/interfaces/recruiter/jobs/post_job/post_job";
import AddJobDetails from "@/components/recruiter/jobs/post_job/AddJobDetails.vue";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";

export default Vue.extend({
  name: "PostNewJob",
  components: {
    GoBackHeader,
    AddJobDetails
  },
  data() {
    return {
      back_icon: require("@/assets/icons/linear/arrow-left-1.svg"),
      posting_options: [] as JobPostingOption[]
    };
  },
  computed: {},
  methods: {}
});
