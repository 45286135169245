























import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  AUTOCOMPLETE_JOB_SEARCH,
  JOBS_POSTED_BY_RECRUITER,
  MATCHING_CANDIDATES
} from "@/store/modules/recruiter/constants";
import { Jobs } from "@/interfaces/data_objects/jobs";
import {
  AutoCompleteJobSearch,
  SearchJobObject
} from "@/interfaces/shared/jobs/auto_complete_job_search";
import { TranslateResult } from "vue-i18n";
import { capitalize_text } from "@/utils/global";

export default Vue.extend({
  name: "AutoCompleteCustomJobSearch",
  data(): AutoCompleteJobSearch {
    return {
      selected_searched_job: null,
      searched_jobs: [],
      search_input: null,
      first_time: true
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      get_posted_jobs: JOBS_POSTED_BY_RECRUITER
    })
  },
  props: {
    is_standardize: {
      type: Boolean,
      default: false
    },
    hide_details: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Array,
      default: () => []
    },
    limit: {
      type: String,
      default: "20"
    },
    placeholder: {
      type: String as PropType<TranslateResult>
    },
    id_return_required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    default_job: {
      type: Object as PropType<SearchJobObject | null>
    }
  },
  mounted() {
    // If default job provided => set
    if (this.default_job) {
      this.searched_jobs.push(this.default_job);
      this.selected_searched_job = this.default_job;
    } else this.first_time = false;
  },
  watch: {
    // Watcher for processing each string written in search field
    async search_input(value) {
      if (this.first_time) {
        this.first_time = false;
        return;
      }
      // If user press clear icon => reset job listing
      if (value === null) this.clear_autocomplete_search_value();
      // If user search value
      else if (value) {
        const obj = {
          dashboard: false,
          job_title:
            typeof value === "string"
              ? value.toLowerCase()
              : value.text.toLowerCase,
          is_standardize: this.is_standardize,
          limit_per_page: parseInt(this.limit)
        };
        const result = await this.autocomplete_job_search(obj);
        if (result.length > 0) this.filter_searched_jobs(result);
      }
    },
    // Watcher when user select job from autocomplete results
    selected_searched_job(value) {
      // if (value) this.fetch_searched_job(value);
      if (value) {
        let return_value = value.text;
        if (this.id_return_required) return_value = value;
        this.$emit("search_job", return_value);
      }
    }
  },
  methods: {
    ...mapActions("recruiter", {
      autocomplete_job_search: AUTOCOMPLETE_JOB_SEARCH,
      fetch_posted_jobs: JOBS_POSTED_BY_RECRUITER,
      get_matching_candidates_by_id: MATCHING_CANDIDATES
    }),
    // Function to process search job to display in autocomplete dropdown
    filter_searched_jobs(jobs: Jobs[]) {
      this.searched_jobs = [];
      for (let job of jobs) {
        const obj = {
          text: capitalize_text(job.job_title),
          job_id: job.jid
        };
        this.searched_jobs.push(obj);
      }
    },
    // Function to reset state & pass null to parent
    clear_autocomplete_search_value() {
      this.reset_state();
      this.$emit("search_job", null);
    },
    // Reset state
    reset_state() {
      this.selected_searched_job = null;
      this.searched_jobs = [];
      this.search_input = null;
    }
  }
});
